import { graphql } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import Layout from "../components/layout/Layout"
import { Head } from "../components/blog/Head"
import Newsletter from "../components/forms/Newsletter"
import { Post } from "../components/blog/Post"
import { Pagination } from "../components/blog/Pagination"
import highlightCode from "../components/utils/HighlightCode"

// props
interface Props {
  data: {
    posts: {
      nodes: Array<{
        id: string
        fields: {
          slug: string
        }
        author: {
          id: string
        }
        body: string
      }>
    }
  }
  pageContext: {
    category: string
    limit: number
    skip: number
    numPages: number
    currentPage: number
    totalCount: number
  }
}
// markup
const BlogsCategoryTemplate = (props: Props) => {
  React.useEffect(() => {
    highlightCode()
  })
  const posts = props.data.posts.nodes

  return (
    <Layout hideNewsletter={true} seo={{ title: "Blog" }} className="blog">
      <div className="container">
        <Head />
        <div className="columns">
          <div className="column is-9 pr-6">
            {posts &&
              posts.map((post, index: number) => (
                <Post
                  isFirst={index === 0}
                  key={`article-${index}`}
                  post={post}
                />
              ))}
            <Pagination
              path={`/blog/category/${props.pageContext.category.toLowerCase()}/`}
              currentPage={props.pageContext.currentPage}
              postsPerPage={props.pageContext.limit}
              totalCount={props.pageContext.totalCount}
            />
          </div>
          <div className="column is-3 pt-6 pb-6">
            <div className="has-background-light px-5 py-5">
              <h2 className="is-size-4 has-text-weight-bold pb-4">
                Get the Spring newsletter
              </h2>
              <Newsletter variant="blog" />
            </div>
          </div>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default BlogsCategoryTemplate

// graphQL queries
export const pageQuery = graphql`
  query BlogsCategoryTemplate($category: String, $skip: Int!, $limit: Int!) {
    posts: allContentfulBlogPost(
      sort: { order: DESC, fields: fields___publishedAt }
      filter: { category: { eq: $category } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...PostFragment
      }
    }
  }
`
